import React from 'react'
import CommingSoon from '../../../assets/images/DemoCommingSoon.png'
import '../../../App.css'

type BannerThreeProps = {
  ID: string
}

export const BannerThree = ({ ID }: BannerThreeProps) => {
  return (
    <div id={ID} className='text-white flex flex-col py-6 px-4 smmd:px-16 xl:px-32 items-left'>
      <div className='grid grid-cols-1 mssn:grid-cols-1 gap-4'>
        <a href='#'>
          <img src={CommingSoon} alt='Comming soon' />
        </a>
      </div>
    </div>
  )
}
