import React from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'
import Instagram from '../../assets/images/logos/Logos_Instagram_Square_White.jpg'
import toTopButton from '../../assets/images/toTopSquare.jpg'
import YouTube from '../../assets/images/logos/Logos_Youtube_Square_White.jpg'
import LinkedIn from '../../assets/images/logos/Logos_Linkedin_Square_White.jpg'
import Sketchfab from '../../assets/images/logos/Logos_Sketchfab_Square_White.jpg'
import Acclogo from '../../assets/images/logos/Acc_Logo_White_Purple.png'

const scrollToTop = () => {
  scroll.scrollToTop()
}

export const InnerFooter = () => {
  return (
    <footer className='md:m-0 grid-cols-1 xl:grid-cols-3 flex-wrap justify-between grid items-start h-auto gap-8 px-[6vw] xl:px-32 py-16 bg-black relative'>
      <div className='flex-col'>
        <div className='xl:basis-1/4 md:basis-1/3 h-12 flex-col smmd:flex-row contents smmd:flex smmd:gap-4 items-baseline'>
          <img
            className='max-h-5 md:max-h-5 nav-break:max-h-7 mb-2.5 nav-break:mb-3'
            src={Acclogo}
            alt={'Accenture Logo'}
          />
          <h2 className='flex text-white text-xl font-big-title'>+</h2>
          <h2 className='flex text-white text-lg font-big-title'>STAR </h2>
        </div>
        <div className='grid mt-6 smmd:mt-0'>
          <a
            className='text-white text-left flex flex-row'
            href='https://www.accenture.com'
            target='_blank'
            rel='noreferrer'
          >
            accenture.com
          </a>
          <a
            className='text-white text-left flex flex-row'
            href='mailto:VSF_helpdesk@accenture.com'
            target='_blank'
            rel='noreferrer'
          >
            Contact
          </a>
          <a
            className={'text-white text-left flex flex-row'}
            href='https://www.accenture.com/us-en/about/privacy-policy'
            target='_blank'
          >
            Privacy
          </a>
          <a
            className={'text-white text-left flex flex-row'}
            href='https://sun.virtualshowfloor.cloud/static/media/PolicyTerms.06ab7ac5.pdf'
            target='_blank'
          >
            Terms & Conditions
          </a>
        </div>
      </div>
      <div className='flex-col'>
        <div className='footerSocialNetworks flex-grow flex flex-col xs:flex-row items-baseline lg:gap-4 gap-2 text-xs xl:mt-12'>
          <ul className='text-xs lg:text-sm 2xl:text-base font-bold list-none items-baseline text-left md:space-y-2.5 space-y-1.5 text-white flex flex-col smmd:flex-row'>
            <li className='flex-col sm:pr-4 smmd:border-r-2'>
              <a href='/'>Home</a>
            </li>
            {/* Edit link when section is launched */}
            <li className='flex-col smmd:px-4 smmd:border-r-2'>
              <a href='/comingSoon' target='_blank'>
                Features
              </a>
            </li>
            <li className='flex-col smmd:px-4'>
              <a href='mailto:VSF_helpdesk@accenture.com' target='_blank'>
                Contact Now
              </a>
            </li>
          </ul>
          <ul className='flex flex-row lg:gap-8 md:gap-4 sm:gap-2 gap-1'>
            <li>
              {' '}
              <a href='https://www.linkedin.com/company/accenture' target='_blank' rel='noreferrer'>
                <img className='white w-10 h-10' src={LinkedIn} alt='LinkedIn Logo' />{' '}
              </a>
            </li>
            <li>
              {' '}
              <a href='https://www.instagram.com/accenture/?hl=en' target='_blank' rel='noreferrer'>
                <img className='white w-10 h-10' src={Instagram} alt='Instagram Logo' />{' '}
              </a>
            </li>
            <img
              className='white w-10 h-10'
              src={Sketchfab}
              alt='Sketchfab Logo'
              title='coming soon'
            />
            <li>
              {' '}
              <a
                href='https://www.youtube.com/channel/UCXprd98Qy-T5QI0xYiNrapA?app=desktop&cbrd=1'
                target='_blank'
                rel='noreferrer'
              >
                <img className='white w-10 h-10' src={YouTube} alt='YouTube Logo' />{' '}
              </a>
            </li>
          </ul>
          <p className='text-xs 2xl:text-sm text-white flex-row text-left font-core-text'>
            @2024 STAR by accenture
          </p>
        </div>
      </div>
      <div className='flex-col gap-8 justify-end'>
        <div className='flex-grow flex flex-col flex-wrap items-end font-core-text absolute top-[17rem] xl:top-[9rem] right-[6vw]'>
          <ul className='items-baseline 2xl:text-xl lg:text-lg md:text-base text-sm list-none text-right text-left md:space-y-2.5 space-y-1.5 text-white'>
            <li>
              <Link className='cursor-pointer' to='top' spy={true} smooth={true}>
                <img src={toTopButton} alt='Scroll to top' onClick={scrollToTop} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
