import React from 'react'
import ColumnContent from './ColumnContent'
import MultiDeviceAccess from '../../../assets/images/MultiDeviceAccessDark.png'
import Assets from '../../../assets/images/1000AssetsDark.png'
import Lock from '../../../assets/images/Lock.png'
import NoDownloads from '../../../assets/images/NoDownloadsDark.png'

type VisionProps = {
  ID: string
}

export const Vision = ({ ID }: VisionProps) => {
  return (
    <div className='text-white items-center justify-center' id={ID}>
      <div className='flex flex-col sm:px-16 xl:px-32 lg:pb-32 p-6 items-left'>
        <h1 className=' text-[4.77vw] smmd:text-[2.77vw] font-big-title text-left'>
          General Benefits
        </h1>
        <div className='flex mt-6'>
          <div className='grid grid-cols-1 smmd:grid-cols-3 gap-4 md:gap-8 lg:gap-16 2xl:gap-32 w-full justify-items-center'>
            <ColumnContent
              image={MultiDeviceAccess}
              title={'Multi-device\naccess'}
              description='STAR gives you accessibility with top quality. Access your projects from most devices while enjoying a fantastic performance for your Metaverse adventures.'
            />
            <ColumnContent
              image={Lock}
              title={'Compliant and\nSecure'}
              description='STAR ensures compliance and security, allowing you to choose your preferred providers and technologies for a tailored and trusted experience.'
            />
            <ColumnContent
              image={NoDownloads}
              title={'No downloads\nno installations'}
              description='STAR is a browser-based digital world platform, so it’s just one login away. Jump in using your preferred web browser and your account credentials. Simple, right?'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
