import React from 'react'
import UtilityInc from '../../../assets/images/Banner01.png'
import '../../../App.css'

type BannerProps = {
  ID: string
}

export const Banner = ({ ID }: BannerProps) => {
  return (
    <div id={ID} className='text-white flex flex-col py-6 px-4 smmd:px-16 xl:px-32 items-left'>
      <div className='grid grid-cols-1 mssn:grid-cols-1 gap-4'>
        <a
          href='https://sun.test-3-0.starplatform.cloud/auth/login?event=STARusecases'
          target='blank'
        >
          <img src={UtilityInc} alt='Utility inc demo' />
        </a>
      </div>
    </div>
  )
}
