import React, { useEffect, useState } from 'react'
// Link disabled for testing purposes. Must be re-enabled along with Store link
import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import Hamburguer from './hamburguer.png'

export const StoreNavigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const toggleNavOpen = () => {
    setIsNavOpen(!isNavOpen)
  }

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 736) {
        setIsNavOpen(true)
      } else {
        setIsNavOpen(false)
      }
    }

    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <nav className='flex justify-right cursor-pointer text-white' data-testid='nav-test' id='nav'>
      <span className='m-4 hm:hidden'>
        <img
          src={Hamburguer}
          className='hamburguer w-8'
          alt={'Hamburguer'}
          onClick={toggleNavOpen}
        />
      </span>
      <ul
        className={`grid hm:flex text-xs md:text-sm lg:text-base list-none z-10 items-center gap-4 absolute hm:relative p-4 w-full text-left left-0 bg-black
        ${
          isNavOpen ? 'opacity-100 top-0' : 'opacity-0 hm:opacity-100 top-[-400px]'
        } transition-all ease-in`}
      >
        <li
          className='font-bold text-[#A100FF] hover:text-white text-2xl ml-[1.4rem] mt-2 text-right hm:hidden'
          onClick={toggleNavOpen}
        >
          X
        </li>
        <li className='font-bold hover:text-[#A100FF]'>
          <Link to='/' target='_self'>
            Home
          </Link>
        </li>
        <li className='font-bold hover:text-[#A100FF]'>
          <Link to='/comingSoon' target='_blank'>
            Features
          </Link>
        </li>
        <li>
          <a href='mailto:VSF_Helpdesk@accenture.com' target='_self'>
            <button className='bg-[#A100FF] hover:bg-white hover:text-[#A100FF] text-white font-bold px-3 pb-1 pt-0.5 mt-0.5'>
              Contact Now
            </button>
          </a>
        </li>
      </ul>
    </nav>
  )
}
