import React from 'react'
import { Vision } from './vision/vision'
import { Solution } from './solution/solution'
import { Footer } from '../footer/footer'
import { Catalogue } from './catalogue/catalogue'
import { Elements } from './elements/elements'
import { Character } from './character/character'
import { Worlds } from './worlds/worlds'
import { Banner } from './banner/banner'
import { BannerTwo } from './bannertwo/bannertwo'
import { BannerThree } from './bannerthree/bannerthree'

export const Sections = () => {
  return (
    <div className='bg-black flex flex-col'>
      <Elements ID='elements' />
      <Character ID='character' />
      <Banner ID='banner' />
      <Worlds ID='worlds' />
      <BannerTwo ID='bannertwo' />
      <BannerThree ID='bannerthree' />
      {/* <Catalogue ID='catalogue' /> */}
      <Vision ID='vision' />
      {/* <Mission ID='mission' /> */}
      <Solution ID='solution' />
      <Footer />
    </div>
  )
}
