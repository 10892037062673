import React from 'react'
import FeatureImage from '../../../assets/images/FeatureImage.png'
import chatdata from '../../../assets/images/ChatData.png'
import support from '../../../assets/images/support.png'
import dataexport from '../../../assets/images/DataExport.png'
import '../../../App.css'

type CharacterProps = {
  ID: string
}

export const Character = ({ ID }: CharacterProps) => {
  return (
    <div id={ID} className='text-white flex flex-col py-6 px-4 smmd:px-16 xl:px-32 items-left'>
      <div className='grid grid-cols-1 mssn:grid-cols-2 gap-8'>
        <div>
          <div className='mssnColOne grid pl-6 xl:pl-32 pr-4 xl:pr-16 xl:px-32 relative gap-4 text-left mx-auto'>
            <h2 className='text-[4.77vw] smmd:text-[2.77vw] font-big-title items-center'>
              STAR Characters
            </h2>
            <p className='text-[3.5vw] sm:text-base xl:text-xl break-words'>
              STAR Characters enable businesses to create and deploy AI personas that interact with
              data, perform specific roles, and integrate seamlessly across functions. These AI
              characters enhance efficiency, customer engagement, training, and decision-making,
              driving smarter business operations through advanced AI capabilities.
            </p>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 mssn:mb-16 text-center'>
              <div className='col-span-1 items-center pb-4 purple'>
                <img src={chatdata} alt='Island Demo 1' />
                <p className='text:[10vw] xl:text-base'>Talk to Data</p>
              </div>
              <div className='col-span-1 items-center pb-4 purple'>
                <img src={support} alt='Island Demo 1' />
                <p className='xl:text-base'>Customizable AI Personas</p>
              </div>
              <div className='col-span-1 items-center pb-4 purple'>
                <img src={dataexport} alt='Island Demo 1' />
                <p className='xl:text-base'>AI-Powered Insights and Seamless Integration</p>
              </div>
            </div>
          </div>
        </div>
        <div className='justify-self-end'>
          <div className='mssnColTwo inline-flex justify-items-end relative mx-4 xl:mr-32'>
            <img className='main' src={FeatureImage} alt='Character Feature Image' />
          </div>
        </div>
      </div>
    </div>
  )
}
