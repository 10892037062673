import React from 'react'
import WorldFeature from '../../../assets/images/WorldFeature.png'

import speed from '../../../assets/images/Speed.png'
import packagei from '../../../assets/images/Package.png'
import paint from '../../../assets/images/Paint.png'
import '../../../App.css'

type WorldsProps = {
  ID: string
}

export const Worlds = ({ ID }: WorldsProps) => {
  return (
    <div id={ID} className='text-white flex flex-col py-6 px-4 smmd:px-16 xl:px-32 items-left'>
      <div className='grid grid-cols-1 mssn:grid-cols-2 gap-8'>
        <div>
          <div className='mssnColOne grid pl-4 xl:pl-32 pr-4 xl:pr-16 xl:px-32 relative gap-4 text-left mx-auto'>
            <h2 className='text-[4.77vw] smmd:text-[2.77vw] font-big-title items-center'>
              STAR Worlds
            </h2>
            <p className='text-[3.5vw] sm:text-base xl:text-xl break-words'>
              STAR Worlds offers immersive, browser-based 3D experiences that redefine how
              businesses engage with commerce, knowledge transfer, and interactive storytelling.
              Accessible across desktop, tablet, mobile, and VR, these virtual spaces create
              impactful environments for learning, collaboration, and customer engagement. With
              flexible options for managed services or self-service, STAR Worlds empowers
              organizations to deliver innovative, cross-platform experiences tailored to their
              needs.
            </p>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 mssn:mb-16 text-center'>
              <div className='col-span-1 items-center pb-4 purple'>
                <img src={speed} alt='Island Demo 1' />
                <p className='text:[10vw] xl:text-base'>Highly Optimized 3D Space</p>
              </div>
              <div className='col-span-1 items-center pb-4 purple'>
                <img src={packagei} alt='Island Demo 1' />
                <p className='xl:text-base'>1000+ assets ready to use</p>
              </div>
              <div className='col-span-1 items-center pb-4 purple'>
                <img src={paint} alt='Island Demo 1' />
                <p className='xl:text-base'>Fully Customizable</p>
              </div>
            </div>
          </div>
          {/* Available button if needed */}
          {/*  <div className='absolute left-0 flex bottom-16 text-xs mx-16 xl:mx-32 gap-4'>
            <a
              href='https://kxdocuments.accenture.com/Contribution/fd142070-8560-4053-a9a8-049c27051f49'
              target='_blank'
              className='py-2 px-8 text-white text-base'
            >
              View Demo Catalogue
            </a>
          </div> */}
        </div>
        <div className='justify-self-end'>
          <div className='mssnColTwo inline-flex justify-items-end relative mx-4 xl:mr-32'>
            <img src={WorldFeature} alt='World Image Feature' />
          </div>
        </div>
      </div>
    </div>
  )
}
