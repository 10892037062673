import React from 'react'
import OceanRace from '../../../assets/images/Banner02.png'
import '../../../App.css'

type BannerTwoProps = {
  ID: string
}

export const BannerTwo = ({ ID }: BannerTwoProps) => {
  return (
    <div id={ID} className='text-white flex flex-col py-6 px-4 smmd:px-16 xl:px-32 items-left'>
      <div className='grid grid-cols-1 mssn:grid-cols-1 gap-4'>
        <a href='https://sun.starplatform.cloud/auth/login?event=DockDemo' target='blank'>
          <img src={OceanRace} alt='Utility inc demo' />
        </a>
      </div>
    </div>
  )
}
