import React from 'react'
import ticket from '../../../assets/images/ticket.png'
import aiavatar from '../../../assets/images/aiavatar.png'
import books from '../../../assets/images/books.png'
import elearning from '../../../assets/images/elearning.png'
import feather from '../../../assets/images/feather.png'
import video from '../../../assets/images/video.png'
import '../../../App.css'

type ElementsProps = {
  ID: string
}

export const Elements = ({ ID }: ElementsProps) => {
  return (
    <div id={ID} className='text-white flex flex-col py-6 px-4 w-full relative '>
      <div className='grid grid-cols-1 mssn:grid-cols-1 gap-4'>
        <div>
          <div className='grid grid-cols-1 sm:grid-cols-6 gap-3 mssn:mb-16 text-center'>
            <div className='col-span-1 items-center pb-4 purple'>
              <img src={ticket} alt='Island Demo 1' />
              <p className='text:[10vw] xl:text-base'>Virtual Events & Fairs</p>
            </div>
            <div className='col-span-1 items-center pb-4 purple'>
              <img src={feather} alt='Island Demo 1' />
              <p className='xl:text-base'>Immersive Branding</p>
            </div>
            <div className='col-span-1 items-center pb-4 purple'>
              <img src={elearning} alt='Island Demo 1' />
              <p className='xl:text-base'>Training & Onboarding</p>
            </div>
            <div className='col-span-1 items-center pb-4 purple'>
              <img src={aiavatar} alt='Island Demo 1' />
              <p className='xl:text-base'>Digital AI Twins</p>
            </div>
            <div className='col-span-1 items-center pb-4 purple'>
              <img src={books} alt='Island Demo 1' />
              <p className='xl:text-base'>Knowledge Exhange</p>
            </div>
            <div className='col-span-1 items-center pb-4 purple'>
              <img src={video} alt='Island Demo 1' />
              <p className='xl:text-base'>Scalable Video Hosting</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
