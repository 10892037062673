import React from 'react'
import { Navigation } from '../navigation/navigation'
import { HeaderVideo } from '../headerVideo/headerVideo'
import logoUrl from '../../assets/images/logos/Acc_Logo_White_Purple.png'
import Banner from '../../components/header/Header_Microsite_FinalV3.png'
import { CreateHeader } from '../sections/createheader/createheader'

interface HeaderProps {
  showVideo: boolean
}

export const Header: React.FC<HeaderProps> = ({ showVideo }) => {
  return (
    <div>
      <div className=''>
        <div className='bg-black min-w-full flex  justify-between px-4 content-center z-50 h-[54px]'>
          <div className='text-white hidden hm:flex w-fit justify-start items-center gap-x-2 font-big-title text-sm nav-break:text-xl'>
            <img
              src={logoUrl}
              className='max-h-5 md:max-h-5 nav-break:max-h-7 mb-2.5 nav-break:mb-3 sm:text-base hidden sm:block'
              alt={'Accenture Logo'}
            />
          </div>
          <Navigation />
        </div>
        <div>
          <CreateHeader ID='createheader' />
        </div>
        <div>
          {showVideo ? (
            <HeaderVideo />
          ) : (
            <img src={Banner} alt='headerImage' className='min-w-full' />
          )}
        </div>
      </div>
    </div>
  )
}
